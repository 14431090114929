// import './App.css';
import Home from './components/home';

function App() {
  return (
    <div>
    <Home/>
    </div>
  );
}

export default App;
